import axios from 'axios'
import { loadToken } from '../utils/sessionStorage';
import { CLIENT_ID, CLIENT_SECRET, GRANT_TYPE } from '../utils/tokenConfig';
import qs from 'querystring'
import { saveToken } from '../utils/sessionStorage';

axios.interceptors.request.use(function (config) {
    if (!config.url.includes('/hia-oauth2')) {
        const token = loadToken().access_token;
        config.headers.Authorization = 'Bearer ' + token;
    }
    return config;
});

const createAxiosResponseInterceptor = () => {
    const params = {
        grant_type: GRANT_TYPE
    }
    const interceptor = axios.interceptors.response.use(
        response => response,
        error => {
            if (error.response.status !== 401) {
                return Promise.reject(error);
            }
            axios.interceptors.response.eject(interceptor);

            return axios.post('https://secureclient.preprod.hia.datafab.cloud/zuul/hia-oauth2/oauth/token', qs.stringify(params), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },

            }).then(response => {
                saveToken(response.data)
                error.response.config.headers['Authorization'] = 'Bearer ' + response.data.access_token;
                return axios(error.response.config);
            }).catch(error => {
                return Promise.reject(error);
            }).finally(createAxiosResponseInterceptor);
        }
    );
}
createAxiosResponseInterceptor();

export const SET_LOGIN_INFORMATION = 'SET_LOGIN_INFORMATION';
export const SET_CHALLENGE_INFORMATION = 'SET_CHALLENGE_INFORMATION';
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR';
export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export const SET_RESPONSE_ERROR = 'SET_RESPONSE_ERROR';
export const SET_VALIDATE_LOGIN_ERROR = 'SET_VALIDATE_LOGIN_ERROR'
export const SET_LOGIN = 'SET_LOGIN';

export const setLoginInformation = (data) => ({ type: SET_LOGIN_INFORMATION, data });
export const setChallengeInformation = (data) => ({ type: SET_CHALLENGE_INFORMATION, data });
export const setLoginError = (data) => ({ type: SET_LOGIN_ERROR, data });
export const setLoggedIn = () => ({ type: SET_LOGGED_IN })
export const setResponseError = (data) => ({ type: SET_RESPONSE_ERROR, data });
export const setValidateLoginError = (data) => ({ type: SET_VALIDATE_LOGIN_ERROR, data })
export const setLogin = (data) => ({ type: SET_LOGIN, data })


export const validateLogin = (login) => (dispatch) => {

    axios.post("https://secureclient.hiasecure.com:8443/api/v1/client-users/challenges", {
        login: login
    }, {
            headers: {
                'Content-Type': 'application/json',
            },

        }).then((response) => {
            if (response.status === 200) {
                dispatch(setLoginInformation(response.data));
                dispatch(setLoginError());
            }
        }).catch(function (error) {
            if (error.response.data.errors) { 
                if (error.response.status === 422) {
                    dispatch(setLogin(login))
                }
                dispatch(setLoginError(error.response.data.errors[0].key));
             }
           
          
        })
}


export const getChallenge = (challengeId, userId) => (dispatch) => {

    axios.get(`https://secureclient.hiasecure.com:8443/api/v1/client-users/challenges/${challengeId}?userId=${userId}`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(setChallengeInformation(response.data))

                const challenge = {
                    idInfo: challengeId,
                    userId: userId,
                    challenge: response.data.challenge
                }

                axios.post(`https://secureclient.hiasecure.com:10443/challenge`,  challenge, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers': 'X-Auth-Token, Origin, X-Requested-With, Content-Type, Accept, Authorization',
                        'Access-Control-Allow-Methods': 'POST, PATCH, GET, PUT, OPTIONS, DELETE'
                    }

                }).then((response) => {
                    console.log('successully', response);
                }).catch(error => {
                    console.log('error: ', error);
                })
            }
        }).catch(function (error) {
            dispatch(setResponseError(error.response.data.errors[0].key));
        })
}

export const answerChallenge = (challengeId, userId, response) => (dispatch) => {

    axios.put(`https://secureclient.hiasecure.com:8443/api/v1/client-users/challenges?challengeId=${challengeId}&response=${response}&userId=${userId}`, {
        headers: {
            'Content-Type': 'application/json'
        },

    }).then((response) => {
        if (response.status === 200) {
            dispatch(setLoggedIn())
        }
    }).catch(function (error) {
        if (error.response.data.result) {
            dispatch(setResponseError(error.response.data.result));
            dispatch(setChallengeInformation(error.response.data))

        }
        else {

            dispatch(setResponseError(error.response.data.errors[0].key));
        }

    })
}

export const createNewConvention = (login) => (dispatch) => {

    axios.post(`https://secureclient.hiasecure.com:8443/api/v1/accounts/new-convention`, { login: login }, {
        headers: {
            'Content-Type': 'application/json'
        },

    }).then((response) => {
        if (response.status === 200) {

            dispatch(setValidateLoginError(true));
        }
    }).catch(function (error) {
        if (error.response.data.errors) { dispatch(setValidateLoginError(error.response.data.errors[0].key)); }
    })
}
