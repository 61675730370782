import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Router } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';
import reducers from './reducers';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { IntlProvider } from "react-intl";
import { addLocaleData } from "react-intl";
import enLocaleData from 'react-intl/locale-data/en';
import frLocaleData from 'react-intl/locale-data/fr';
import messages_en from "./utils/messages/en.json";
import messages_fr from "./utils/messages/fr.json";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { getApplicationToken } from './actions';

addLocaleData(enLocaleData);
addLocaleData(frLocaleData);

const messages = {
    'fr': messages_fr,
    'en': messages_en
};

export const store = createStore(
    reducers(),
    composeWithDevTools(
        applyMiddleware(
            thunk,
            createLogger({ collapsed: true }),
        ),
    ),
);
store.dispatch(getApplicationToken());
const history = createBrowserHistory();
const theme = createMuiTheme({
    overrides: {
        MuiButton: {
            label: {
                color: "#fff"
            }
        }
    },
});
const ROOT = (
    <IntlProvider locale={'en'} messages={messages['en']}>
        <Provider store={store}>
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <Router history={history}>
                    <App />
                </Router>
            </MuiThemeProvider>
        </Provider>
    </IntlProvider>
);
ReactDOM.render(ROOT, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
